@import url('https://fonts.googleapis.com/css2?family=Tinos:ital,wght@0,400;0,700;1,400;1,700&display=swap');

body , pre {
  margin: 0;
  font-family: "Tinos", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color:#F9F8F6; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.noAnimation{
}

.fadeIn {
  animation: 0.5s fadeIn forwards;
}

.fadeOut {
  animation: 0.5s fadeOut forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-20px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(-100%, 0);
    opacity: 1;
  }
}
@font-face {
font-family: '__tinos_afb1af';
src: url(/_next/static/media/7b61cb247e25480f-s.p.woff) format('woff');
font-display: swap;
}@font-face {font-family: '__tinos_Fallback_afb1af';src: local("Arial");ascent-override: 97.47%;descent-override: 23.66%;line-gap-override: 4.65%;size-adjust: 91.43%
}.__className_afb1af {font-family: '__tinos_afb1af', '__tinos_Fallback_afb1af'
}

:root{--max-width: 1100px;--border-radius: 12px;--font-mono: "Outfit", sans-serif;--foreground-rgb: 0, 0, 0;--background-start-rgb: 214, 219, 220;--background-end-rgb: 255, 255, 255;--primary-glow: conic-gradient( from 180deg at 50% 50%, #16abff33 0deg, #0885ff33 55deg, #54d6ff33 120deg, #0071ff33 160deg, transparent 360deg );--secondary-glow: radial-gradient( rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) );--tile-start-rgb: 239, 245, 249;--tile-end-rgb: 228, 232, 233;--tile-border: conic-gradient( #00000080, #00000040, #00000030, #00000020, #00000010, #00000010, #00000080 );--callout-rgb: 238, 240, 241;--callout-border-rgb: 172, 175, 176;--card-rgb: 180, 185, 188;--card-border-rgb: 131, 134, 135}@media(prefers-color-scheme: dark){:root{--foreground-rgb: 255, 255, 255;--background-start-rgb: 0, 0, 0;--background-end-rgb: 0, 0, 0;--primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));--secondary-glow: linear-gradient( to bottom right, rgba(1, 65, 255, 0), rgba(1, 65, 255, 0), rgba(1, 65, 255, 0.3) );--tile-start-rgb: 2, 13, 46;--tile-end-rgb: 2, 5, 19;--tile-border: conic-gradient( #ffffff80, #ffffff40, #ffffff30, #ffffff20, #ffffff10, #ffffff10, #ffffff80 );--callout-rgb: 20, 20, 20;--callout-border-rgb: 108, 108, 108;--card-rgb: 100, 100, 100;--card-border-rgb: 200, 200, 200}}*{box-sizing:border-box;padding:0;margin:0}html,body{max-width:100vw;overflow-x:hidden}body{color:rgb(var(--foreground-rgb))}a{color:inherit;text-decoration:none}h1,h2,h3,h4,h5,h6{font-weight:300}p{font-weight:100}@media(prefers-color-scheme: dark){html{color-scheme:dark}}


@font-face {
    font-family: aAnotherTag;
    src: url(/_next/static/media/aAnotherTag.2d9ff344.woff);
}

:root {
    --FooterTextFeildBorder: #27231B;
    --SurfacePurple: #461E5B;
    --TextYellow: #F9CA11;
    --Black: #000000;
    --Text50: rgba(39, 35, 27, 0.5);
    --TextSecondary: #8D8471;
    --TextInvert: #F9F8F6;
    --Netural100: #F6F2E9;
}

.oh_my_ink_logo {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--TextYellow);
}

.sign_in_title {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 41px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--TextYellow);
}

.warning_message {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    padding: 0  20px;
}

.social_media_sign_in{
    background: var(--Netural100);
    display: block;
    width: 100%;
}

.social_media_sign_in span{
    color: rgba(39,35,27,0.75);
}

.sign_in_label_1,
.manageSubscription_label_2 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: var(--TextYellow);
}

.sign_in_label_2 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--FooterTextFeildBorder);
}

.sign_in_label_2_link,
.checkout_tatoo_cancel_link {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: var(--SurfacePurple);
}

.sign_in_label_3 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: right;
    color: var(--TextYellow);
}

.sign_in_label_4 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--FooterTextFeildBorder);
}

.sign_in_label_5,
.sign_in_label_6 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 11px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--FooterTextFeildBorderr);
}

.loading_oh_my_ink_logo {
    font-family: aAnotherTag;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--TextYellow);
}

.loading_label_1 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 17.85px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--TextYellow);
}

.footer_about_label {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    align-items: center;
    color: var(--TextYellow);
}

.footer_link_label {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: var(--TextYellow);
}

.landing_header_0 {
    font-family: aAnotherTag;
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 41px;
    color: var(--TextYellow);
}
.landing_header_1,
.landing_header_2,
.landing_header_3 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 41px;
    color: var(--FooterTextFeildBorder);
}

.landing_header_4 {
    font-family: aAnotherTag;
    font-style: normal;
    font-weight: 700;
    font-size: 200px;
    line-height: 100px;
    color: var(--TextYellow);
}

.artistProfileDetail_label_1,
.bookingFormArtistProfileDetail_label_1 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 40px;
    text-align: center;
    color: var(--FooterTextFeildBorder);
}

.tatoo_detail_artist_name {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    color: var(--FooterTextFeildBorder);
}

.artistProfileDetail_label_2,
.bookingFormArtistProfileDetail_label_2 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 39px;
    text-align: center;
    color: var(--Text50);
}

.artistProfileDetail_label_3,
.bookingFormArtistProfileDetail_label_3 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--Text50);
}

.artistProfileDetail_label_4 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 27px;
    text-align: center;
    color: var(--Text50);
}

.artistProfileDetail_label_5,
.artistProfileDetail_label_6 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 39px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--Text50);
}

.artistProfileDetail_button_save_liked {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--TextYellow);
}

.artistProfileDetail_button_save_unliked,
.artistProfileDetail_button_inquiry {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--FooterTextFeildBorder);
}

.artistProfile_header_1 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    color: var(--FooterTextFeildBorder);
}

.appbar_button_label {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    display: flex;
    align-items: flex-end;
    text-align: right;
    color: var(--TextYellow);
}

.landing_tryon_try_button_label,
.artist_profile_try_button_label {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 12.8263px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--SurfacePurple);
}

.landing_tryon_buy_button_label,
.artist_profile_buy_button_label {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 12.8263px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--TextYellow);
}

.featured_artist_title {
    font-family: aAnotherTag;
    font-style: normal;
    font-weight: 700;
    font-size: 44.9032px;
    line-height: 36px;
    text-align: center;
    color: var(--TextYellow);
    text-shadow: -0.5px -0.5px 0 var(--Black), 0.5px -0.5px 0 var(--Black), -0.5px 0.5px 0 var(--Black), 0.5px 0.5px 0 var(--Black);
}

.myster_gift_title {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 36px;
    text-align: center;
    color: var(--SurfacePurple);
    text-shadow: -0.5px -0.5px 0 var(--Black), 0.5px -0.5px 0 var(--Black), -0.5px 0.5px 0 var(--Black), 0.5px 0.5px 0 var(--Black);
}

.myster_gift_label_1 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: var(--Text50);
}

.myster_gift_label_2 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: var(--Text50);
}

.myster_gift_label_3,
.tatoo_detail_label_3 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: var(--FooterTextFeildBorder);
}

.feature_artists_artist_fragment_location {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 40px;
    color: var(--Text50);
}

.feature_artists_artist_fragment_artist_name {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    color: var(--FooterTextFeildBorder);
}

.feature_artists_artist_fragment_username,
.feature_artists_artist_fragment_style,
.tatoo_detail_username {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: var(--Text50);
}

.featured_artists_try_button_label {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 9.49073px;
    line-height: 11px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--SurfacePurple);
}

.featured_artists_buy_button_label {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 9.49073px;
    line-height: 11px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--TextYellow);
}

.menu_title {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: var(--FooterTextFeildBorder);
}

.menu_item,
.checkout_tatoo_label_2 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: var(--FooterTextFeildBorder);
}

.menu_logout_button {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 10px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--SurfacePurple);
}

.my_order_track_order_button {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--TextInvert);
}

.checkout_add_addres_button,
.shipping_saved_button,
.checkout_token_pay_now_button {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.41px;
    color: var(--TextInvert);
}

.tatoo_detail_buy_button {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--TextYellow);
}

.myster_gift_buy_button,
.buy_token_buy_button,
.booking_form_submit_button {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--TextYellow);
}

.buy_token_label_1 {
    font-family: aAnotherTag;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: var(--TextYellow);
}

.buy_token_label_2 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: var(--TextSecondary);
}

.buy_token_token_frame {
    font-family: 'Tinos' !important;
    box-sizing: border-box;
    border: 3px solid var(--SurfacePurple);
    border-radius: 30px;
}

.buy_token_token_label_1 {
    font-family: 'Tinos' !important;
    box-sizing: border-box;
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 44px;
    color: var(--SurfacePurple);
}

.buy_token_token_label_2 {
    font-family: 'Tinos' !important;
    box-sizing: border-box;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 55px;
    color: var(--SurfacePurple);
}

.bookingFormArtistProfileDetail_label_4 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    color: var(--FooterTextFeildBorder);
}

.bookingFormArtistProfileDetail_label_5 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--FooterTextFeildBorder);
}

.contact_us_input_form_label_1,
.contact_us_input_form_label_2,
.contact_us_input_form_label_3,
.contact_us_input_form_label_4,
.manageSubscription_label_4 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: var(--Text50);
}

.contact_us_input_form_upload_button {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 24px;
    color: var(--Text50);
}


.booking_form_input_form_label_1,
.booking_form_input_form_label_2,
.booking_form_input_form_label_3,
.booking_form_input_form_label_4,
.booking_form_input_form_label_5,
.booking_form_input_form_label_6,
.booking_form_input_form_label_7,
.booking_form_input_form_label_8,
.booking_form_input_form_label_9,
.booking_form_input_form_label_10 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: var(--FooterTextFeildBorder);
}

.booking_form_input_form_upload_button {
    font-family: 'Tinos' !important;
    box-sizing: border-box;
    opacity: 0.3;
    border: 1px solid var(--FooterTextFeildBorder);
    border-radius: 30px;
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--FooterTextFeildBorder);
}

.confirmation_dialog_label_1 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: var(--FooterTextFeildBorder);
}

.my_order_title {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 46px;
    text-align: center;
    color: var(--SurfacePurple);
    text-shadow: -0.5px -0.5px 0 var(--Black), 0.5px -0.5px 0 var(--Black), -0.5px 0.5px 0 var(--Black), 0.5px 0.5px 0 var(--Black);
}

.my_order_label_1 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--Text50);
}

.my_order_order_item_label_1,
.my_order_order_item_label_2 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--SurfacePurple);
}

.saved_savedCategory_title {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--FooterTextFeildBorder);
}

.saved_title {
    font-family: aAnotherTag;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    color: var(--TextYellow);
}

.saved_category_title,
.checkout_token_label_1 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    color: var(--TextYellow);
}

.return_policy_title,
.tnc_title,
.about_us_title,
.contact_us_title,
.faq_title {
    font-family: aAnotherTag;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: var(--TextYellow);
}

.about_us_label_1 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: var(--SurfacePurple);
}

.tatoo_detail_temporary_bundle_name {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: var(--SurfacePurple);
}

.tatoo_detail_label_1 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 16px;
    color: var(--FooterTextFeildBorder);
}

.tatoo_detail_label_2 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: var(--FooterTextFeildBorder);
}

.checkout_tatoo_label_1,
.shipping_label_1 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    color: var(--FooterTextFeildBorder);
}

.checkout_tatoo_label_3,
.checkout_tatoo_label_5,
.checkout_tatoo_label_6,
.checkout_tatoo_label_7 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: var(--FooterTextFeildBorder);
}

.checkout_tatoo_label_4 {
    font-family: 'Tinos' !important;
    box-sizing: border-box;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: var(--Text50);
}

.shipping_label_2,
.shipping_label_4 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: var(--Text50);
}

.shipping_label_3 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 11px;
    letter-spacing: 0.2px;
    color: var(--Text50);
}

.checkout_tatoo_pay_name {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: var(--Text50);
}

.checkout_tatoo_pay_shipping_address {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--Text50);
}

.checkout_token_label_2 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: var(--FooterTextFeildBorder);
}

.checkout_token_label_3 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 25px;
    letter-spacing: 0.02em;
    color: var(--FooterTextFeildBorder);
}

.contact_us_form_my_question_is_about {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--FooterTextFeildBorder);
}

.register_label_1 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--Text50);
}

.artistDashboardProfileDetail_editProfile_button,
.manageSubscription_cancel_button {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 11px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--Text50);
}

.artistDashboardProfileDetail_upload_button_plus {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 40px;
    display: flex;
    align-items: center;
    color: var(--Text50);
}

.artistDashboardProfileDetail_upload_button_text {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 40px;
    display: flex;
    align-items: center;
    color: var(--Text50);
}

.artistDashboard_revenueBackground {
    font-family: 'Tinos' !important;
    width: 361px;
    height: 133px;
    background: var(--Netural100);
    border-radius: 33px;
}

.artistDashboard_revenueHeading,
.editProfile_label_1 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 40px;
    text-align: center;
    color: var(--FooterTextFeildBorder);
}

.artistDashboard_revenueContent {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 40px;
    margin-top: -15px;
    text-align: center;
    color: var(--FooterTextFeildBorder);
}

.uploadDrawer_label_1 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.25px;
    color: var(--FooterTextFeildBorder);
}

.uploadDrawer_box_1 {
    font-family: 'Tinos' !important;
    box-sizing: border-box;
    border: 1px solid var(--Text50);
    border-radius: 20px;
}

.uploadDrawer_upload_button,
.uploadDrawer_confirm_button {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--SurfacePurple);
}

.manageSubscription_label_1,
.manageSubscription_label_3 {
    font-family: 'Tinos' !important;
    box-sizing: border-box;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--SurfacePurple);
}

.manageSubscription_button_current_plan {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--TextInvert);
}

.landscapeDialog_label_1 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    color: var(--TextInvert);
}

.landscapeDialog_label_2 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: var(--TextInvert);
}

.checkout_scription_label_1 {
    font-family: 'Tinos' !important;
    box-sizing: border-box;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 27.6px;
    color: var(--SurfacePurple);
}

.bottom_app_bar{
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    text-align: right;
    color: var(--SurfacePurple);
}





/* Try on page */

.image_cropper_panel {
    width: 100%;

}

.tryon_title {
    margin-top: 20px;
    font-family: aAnotherTag;
    font-style: normal;
    font-weight: 700;
    font-size: 100px;
    line-height: 44px;
    text-align: center;
    color: var(--TextYellow);
    text-shadow: -0.5px -0.5px 0 var(--Black), 0.5px -0.5px 0 var(--Black), -0.5px 0.5px 0 var(--Black), 0.5px 0.5px 0 var(--Black);
}


.btn_upload{
    position: relative;
    cursor: pointer;
}

.btn_upload .MuiButton-root{
    padding: 0;
}

.tryon_second_title{
    color: var(--text-text-50, rgba(39, 35, 27, 0.50));
    text-align: center;
    font-family: Tinos;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.btn_upload .file_input{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.try_on_page_wrapper{
    padding-left: 30px;
    padding-right: 30px;
}

.try_on_page_wrapper .button_wrapper{
    display: inline-flex;
    padding: 52px 45px;
    flex-direction: column;
    align-items: flex-start;
    gap: 41px;
    border-radius: 20px;
    border: 6px solid var(--Surface-surface-purple, #461E5B);
    margin-top: 30px;
}

.try_on_page_wrapper .button_wrapper.yellow{
    border-radius: 20px;
    border: 6px solid var(--Text-text-yellow, #F9CA11);
}

.try_on_page_wrapper .button_wrapper .btn_try_on{
    display: flex;
    width: 204px;
    padding: 15px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 16px;
    border: 3px solid var(--Text-text-purple, #461E5B);
    color: var(--Text-text-purple, #461E5B);
    text-align: center;
    font-family: Tinos;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.try_on_page_wrapper .button_wrapper.yellow .btn_try_on{
    display: flex;
    width: 204px;
    padding: 15px 52px;
    justify-content: center;
    align-items: center;
    gap: 10px;border-radius: 16px;
    background: var(--Surface-surface-yellow, #F9CA11);
    border: none;
}

.try_on_page_wrapper .button_wrapper .btn_try_on .MuiButton-text{
    color: var(--Text-text-purple, #461E5B);
    text-align: center;
    font-family: Tinos;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.try_on_page_wrapper .text_editor_guide{
    margin-top: 20px;
}

.try_on_page_wrapper .text_editor_wrapper{
    margin-top: 20px;
    display: inline-flex;
    height: 113px;
    width: 100%;
    padding: 0px 10px;
    align-items: center;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px solid var(--text-text-50, rgba(39, 35, 27, 0.50));
}

.try_on_page_wrapper .text_editor_wrapper textarea{
    background: none;
    color: #000;
}

.try_on_page_wrapper .font_selection_container{
    margin-top: 20px;
    width: 100%;
}

.try_on_page_wrapper .font_selection_container .font_selection_inner_container{
    /* padding: 0 26px; */
    /* display: i */
    /* flex-shrink: 0; */
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.try_on_page_wrapper .gen_ai_textarea{
    display: flex;
    width: 100%;
    height: 100%;
    padding: 10px;
    align-items: center;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px solid var(--text-text-50, rgba(39, 35, 27, 0.50));
}

.try_on_page_wrapper .gen_ai_textarea fieldset{
    border: none;
}

.try_on_page_wrapper .gen_ai_guide{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.try_on_page_wrapper .try_on_subtitle{
    color: var(--text-text-50, rgba(39, 35, 27, 0.50));
    text-align: center;
    font-family: Tinos;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.try_on_page_wrapper .gen_ai_style_btn{
    width: 33%;
    padding-top: 33%;
    position: relative;
}

.try_on_page_wrapper .gen_ai_style_btn span{
    position: absolute;
    width: 100%;
    text-align: center;
    height: 25px;
    top: 50%;
    margin-top: -12px;
}

.gen_ai_btn_wrapper{
    padding-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
}

@media (min-width: 600px){
    .try_on_page_wrapper .gen_ai_style_btn{
        width: 100px;
        padding-top: 100px;
        margin: 0 5px;
    }

    .gen_ai_btn_wrapper{
        
    }    
}

.btn_font_select{
    display: flex;
    width: calc(50% - 3px);
    height: 55px;
    padding: 6px 0px 4px 0px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 21px !important;
    border: 1px solid var(--Text-text-primary, #27231B) !important;
    color: #27231B !important;
    margin-right: 6px !important;
    margin-bottom: 6px !important;
}

.btn_font_select.selected{
    background: #CCC;
}

.btn_font_select.odd{
    margin-right: 0 !important;
}

.ctrl_btn_wrapper{
    margin-top: 20px;
}

.ctrl_btn_wrapper .btn_confirm{
    display: inline-flex;
    padding: 8px 32px;
    align-items: center;
    gap: 12px;
    border-radius: 73.043px;
    background: var(--Surface-surface-purple, #461E5B);
    color: var(--Text-text-yellow, #F9CA11);
    text-align: center;
    font-family: Tinos;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.ctrl_btn_wrapper .vertical_btn_container{
    justify-content: center;
    display: flex;
}

.ctrl_btn_wrapper .vertical_btn{
    margin: 10px auto 0;
}

.ctrl_btn_wrapper .btn_back{
    display: flex;
    width: 100%;
    height: 18px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: var(--TextYellow);
    text-align: center;
    font-family: Tinos;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    margin-top: 20px;
}


.image_wrapper .body_part_item{
    display: flex;
    align-items: center;
    border-radius: 30.237px;
    border: 1.008px solid var(--Border-border-primary, #413A2E);
    overflow: hidden;
    margin-top: 16px;
}

.image_wrapper .body_part_item .MuiButton-root{
    padding: 0;
}

.image_wrapper .body_part_item img{
    display: block;
    width: 100%;
}

.image_wrapper .body_part_item.selected{
    background: #666;
    border: 3px solid #461E5B;
}

.image_wrapper .body_part_item.selected img{
    opacity: 0.5;
}

.image_wrapper .canvas_body_img{
    display: block;
    width: 100%;
}

.image_wrapper .body_part_preview_img{
    display: block;
    width: 100%;
    height: auto;
}


@media  screen and (min-width: 1024px) {
    .image_wrapper .body_part_item_wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .image_wrapper .body_part_item{
        flex-basis: 50%;
    }
    
    .image_wrapper.layering_canvas{
        /* padding: 0 100px; */
        padding: 50px 0 20px;
    }
    
}




/* Override */
.artistProfileDetail_label_3,
.bookingFormArtistProfileDetail_label_3 {
    width: calc(100% - 40px);
    margin: 0 20px;
    text-align: justify;
}

.nearbyTattooShop_label_1 {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 26px;
    text-align: center;
    color: var(--SurfacePurple);
}

.nearby_tattoo_shop_fragment_displayName {
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    color: var(--FooterTextFeildBorder);
}

.nearby_tattoo_shop_fragment_style{
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    display: flex;
    align-items: flex-start;
    color: var(--Text50);
    margin-bottom: 5px;
}

.nearby_tattoo_shop_fragment_style_2{
    font-family: 'Tinos' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    display: flex;
    align-items: flex-start;
    color: var(--Text50);
    margin-bottom: 5px;
}

.nearby_tattoo_shop_fragment_button {
    font-family: 'Tinos' !important;;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #461E5B;
}


